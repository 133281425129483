import { faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BotaoExcluir = ({ onClick }: { onClick: () => void }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="py-2 px-3 rounded-full bg-red-500 hover:bg-red-700">
            <FontAwesomeIcon
                icon={faTrash}
                className="text-white"
            />
        </button>
    );
};

export const BotaoSubmitMensagem = () => {
    return (
        <button
            className="rounded-full pr-2 hover:cursor-pointer "
            type="submit">
            <FontAwesomeIcon
                icon={faPaperPlane}
                size="xl"
                color="#226020"
            />
        </button>
    );
};

export const BotaoAzul = ({
    className,
    onClick,
    label,
    tipo,
}: {
    className?: string;
    onClick?: () => void;
    label: string;
    tipo?: "button" | "reset" | "submit";
}) => {
    return (
        <button
            className={`rounded-full px-2 py-1 bg-blue-300 hover:cursor-pointer ${className}`}
            type={tipo ?? "button"}
            onClick={onClick}>
            {label}
        </button>
    );
};
