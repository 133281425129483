import { useConversaStore } from "@/store/conversaStore";
import { formatarTelefone } from "@/util/FormatacaoUtils";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

const Cabecalho = () => {
    const conversa = useConversaStore.getState().conversa;
    return (
        <div
            className={`bg-green-800 text-neutral-100 w-full flex border-b-[1px] py-2 px-4 lg:px-6
            justify-around items-center shadow-sm shadow-gray-200 shadow-bottom gap-2`}>
            <div className="flex w-full items-center">
                <div className={`flex flex-col gap-1 items-start w-full ml-10`}>
                    <Link
                        href={`${process.env.NEXT_PUBLIC_CORE_MAPA_OPERACIONAL_PESQUISA_CONTATO_URL}?search=${conversa?.nomeContato}`}
                        target="_blank"
                        className="flex font-semibold items-center hover:underline">
                        {conversa?.nomeContato}
                        <FontAwesomeIcon
                            icon={faLink}
                            size="xs"
                            className="ml-1"
                            inverse
                        />
                    </Link>
                    <span className="text-sm font-light">{formatarTelefone(conversa?.telContato)}</span>
                </div>
            </div>
        </div>
    );
};

export default Cabecalho;
