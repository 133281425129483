import { alterarSetorConversa } from "@/api/chatAPI";
import { ConversaType } from "@/types/ConversaTypes";
import { SetorEnum } from "@/util/EnumUtils";

export const BotaoTransferir = ({
    conversa,
    usuarioAlteracao,
}: {
    conversa: ConversaType;
    usuarioAlteracao: string | undefined;
}) => {
    return (
        <div
            className="absolute w-full left-[-100%] top-16 flex flex-col items-start justify-start z-30 text-slate-100 shadow-lg 
            bg-green-600 mt-2">
            <ul className="w-full">
                {Object.values(SetorEnum)
                    .sort()
                    .map((setor) => (
                        <li
                            key={setor}
                            className="w-full text-left p-2 hover:cursor-pointer hover:bg-green-950"
                            onClick={() => alterarSetorConversa(setor, conversa.codConversa, usuarioAlteracao)}>
                            {setor}
                        </li>
                    ))}
            </ul>
        </div>
    );
};
