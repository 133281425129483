export const EsqueletoConversas = () => {
    const renderEsqueletos = () => {
        const esqueletos = [];
        for (let i = 0; i < 5; i++) {
            esqueletos.push(
                <div
                    key={i}
                    className="mt-1 relative w-full flex items-center space-x-3 px-3 py-4 border-b
                    bg-neutral-300 transition cursor-pointer animate-pulse flex-row ">
                    <div className="min-w-full  flex-1">
                        <span className="absolute inset-0" />
                        <div className="flex justify-between items-center">
                            <p className="w-1/2 h-6 bg-gray-400"></p>
                            <span className="w-1/4 h-6 bg-gray-400"></span>
                        </div>
                        <p className="w-full h-6 bg-gray-400 mt-4"></p>
                    </div>
                </div>
            );
        }
        return esqueletos;
    };

    return <>{renderEsqueletos()}</>;
};
