import {
    faArrowLeft,
    faArrowRight,
    faExternalLinkAlt,
    faSearchMinus,
    faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalWorkerOptions, PDFDocumentProxy, getDocument } from "pdfjs-dist";
import React, { useEffect, useRef, useState } from "react";

interface PdfRenderProps {
    s3Url: string;
    fileName?: string;
}

const PdfRender: React.FC<PdfRenderProps> = ({ s3Url, fileName }) => {
    GlobalWorkerOptions.workerSrc = window.location.origin + "/pdf.worker.min.mjs";
    const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [zoom, setZoom] = useState<number>(1.0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isRenderingRef = useRef<boolean>(false); // Ref para rastrear se está renderizando

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const loadingTask = getDocument(s3Url);
                const loadedPdf = await loadingTask.promise;
                setPdfDoc(loadedPdf);
            } catch (error) {
                console.error("Erro ao carregar o PDF: ", error);
            }
        };
        loadPdf();
    }, [s3Url]);

    useEffect(() => {
        const renderPage = async () => {
            if (!pdfDoc || isRenderingRef.current) return; // Verifica se já está renderizando
            isRenderingRef.current = true; // Marca como renderizando

            const page = await pdfDoc.getPage(currentPage);
            const viewport = page.getViewport({ scale: zoom });

            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context!,
                    viewport: viewport,
                };

                await page.render(renderContext).promise;
            }

            isRenderingRef.current = false;
        };

        renderPage();
    }, [pdfDoc, currentPage, zoom]);

    const zoomIn = () => setZoom((prevZoom) => prevZoom + 0.25);
    const zoomOut = () => setZoom((prevZoom) => (prevZoom > 0.5 ? prevZoom - 0.25 : prevZoom));
    const nextPage = () => pdfDoc && currentPage < pdfDoc.numPages && setCurrentPage((prevPage) => prevPage + 1);
    const prevPage = () => pdfDoc && currentPage > 1 && setCurrentPage((prevPage) => prevPage - 1);

    const handleAbrirNovaPagina = () => {
        const link = document.createElement("a");
        link.href = s3Url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex flex-col items-center justify-center w-full h-full p-2">
            <span className="flex justify-between w-full gap-1">
                <span className="flex space-x-1">
                    <button onClick={prevPage}>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            size="xl"
                            color="#15803D"
                        />
                    </button>
                    <button onClick={nextPage}>
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            size="xl"
                            color="#15803D"
                        />
                    </button>
                </span>

                {pdfDoc && (
                    <span className="mt-2 text-sm text-gray-700">
                        Página {currentPage} de {pdfDoc.numPages}
                    </span>
                )}

                <span className="flex space-x-1">
                    <button onClick={zoomOut}>
                        <FontAwesomeIcon
                            icon={faSearchMinus}
                            size="xl"
                            color="#15803D"
                        />
                    </button>
                    <button onClick={zoomIn}>
                        <FontAwesomeIcon
                            icon={faSearchPlus}
                            size="xl"
                            color="#15803D"
                        />
                    </button>
                    <button onClick={handleAbrirNovaPagina}>
                        <FontAwesomeIcon
                            icon={faExternalLinkAlt}
                            size="xl"
                            color="#15803D"
                        />
                    </button>
                </span>
            </span>

            <div className="relative">
                <canvas
                    ref={canvasRef}
                    className="w-full h-full"
                />
            </div>
        </div>
    );
};

export default PdfRender;
