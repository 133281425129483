"use client";
import { retornaInfoUsuarioLogado } from "@/api/chatAPI";
import Sidebar from "@/components/chat/barraLateral/Sidebar";
import ChatMain from "@/components/chat/conversa/Container";
import Main from "@/components/main/Main";
import Modal from "@/components/modal/Modal";
import { useUsuarioStore } from "@/store/usuarioStore";
import { CookieEnum } from "@/util/ChatUtils";
import { getCookie } from "cookies-next";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function Home() {
    const router = useRouter();
    const { alterarUsuario, removerUsuario } = useUsuarioStore();
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_AMBIENTE === "local") {
            return;
        }
        const loginUsuario = getCookie(CookieEnum.LOGIN_USUARIO);
        if (!loginUsuario) {
            removerUsuario();
            router.push("/login");
        }

        retornaInfoUsuarioLogado(loginUsuario as string)
            .then((usuario) => {
                alterarUsuario(usuario);
            })
            .catch((erro) => {
                toast(erro.message, {
                    toastId: "erro-info-usuario",
                    type: "error",
                });
            });
    }, []);

    return (
        <Main>
            <Modal />
            <Sidebar />
            <ChatMain />
        </Main>
    );
}
