"use client";
import { alterarUsuarioResponsavel } from "@/api/chatAPI";
import { ptBR } from "@/i18n/pt-br";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { MenuLateralEnum } from "@/types/MenuTypes";
import { faBoxArchive, faChainSlash, faComments, faUser, faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const i18n = ptBR;
export default function MenuLateral() {
    const { menuLateral, alterarMenuLateral } = useListagemLateralStore();
    const { usuario } = useUsuarioStore();

    const clickDesassociarTodasConversas = () => {
        if (usuario) {
            alterarUsuarioResponsavel(usuario.login, null, true, usuario.nomeCompleto);
        }
    };

    return (
        <nav className="w-10 h-full bg-green-900">
            <ul className="h-full flex flex-col justify-between pb-4">
                <div className="flex flex-col">
                    <button
                        className={`mx-auto hover:cursor-pointer w-full py-4 ${menuLateral === MenuLateralEnum.CONVERSAS_ABERTAS ? "bg-green-700" : ""}`}
                        onClick={() => alterarMenuLateral(MenuLateralEnum.CONVERSAS_ABERTAS)}
                        title={MenuLateralEnum.CONVERSAS_ABERTAS}>
                        <FontAwesomeIcon
                            icon={faComments}
                            size="lg"
                            inverse
                        />
                    </button>
                    <button
                        className={`mx-auto hover:cursor-pointer w-full py-4 ${menuLateral === MenuLateralEnum.MEUS_SETORES ? "bg-green-700" : ""}`}
                        onClick={() => alterarMenuLateral(MenuLateralEnum.MEUS_SETORES)}
                        title={MenuLateralEnum.MEUS_SETORES}>
                        <FontAwesomeIcon
                            icon={faUsersRectangle}
                            size="lg"
                            inverse
                        />
                    </button>
                    <button
                        className={`mx-auto hover:cursor-pointer w-full py-4 ${menuLateral === MenuLateralEnum.MINHAS_CONVERSAS ? "bg-green-700" : ""}`}
                        onClick={() => alterarMenuLateral(MenuLateralEnum.MINHAS_CONVERSAS)}
                        title={MenuLateralEnum.MINHAS_CONVERSAS}>
                        <FontAwesomeIcon
                            icon={faUser}
                            size="lg"
                            inverse
                        />
                    </button>
                    <hr className="opacity-50" />
                    <button
                        className={`mx-auto hover:cursor-pointer w-full py-4 ${menuLateral === MenuLateralEnum.SOLUCIONADO ? "bg-green-700" : ""}`}
                        onClick={() => alterarMenuLateral(MenuLateralEnum.SOLUCIONADO)}
                        title={MenuLateralEnum.SOLUCIONADO}>
                        <FontAwesomeIcon
                            icon={faBoxArchive}
                            size="lg"
                            inverse
                        />
                    </button>
                </div>
                <button
                    className="hover:cursor-pointer"
                    title={i18n.tit_desassociar_minhas_conversas}
                    onClick={clickDesassociarTodasConversas}>
                    <FontAwesomeIcon
                        icon={faChainSlash}
                        size="lg"
                        inverse
                    />
                </button>
            </ul>
        </nav>
    );
}
