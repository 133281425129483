export const ptBR = {
    //ALT - ALT LABELS
    alt_logo_rodosafra: "Logo Rodosafra",

    //NPR - NOMES PARAMETRIZADOS
    npr_usuario_padrao: "Sistema",

    //BTN - BOTÃO
    btn_remover: "Remover",
    btn_cancelar: "Cancelar",
    btn_criar_conversa: "Criar Conversa",
    btn_carregar_mais_mensagens: "Carregar mais mensagens",
    btn_alterar_nome: "Alterar Nome",

    //ERR - ERRORS
    err_listagem_setores: "Falha ao carregar lista de setores. Tente novamente mais tarde.",
    err_usuario_nao_autenticado: "Favor realizar o login no sistema.",
    err_buscar_mensagens: "Ocorreu um erro ao buscar as mensagens. Tente novamente mais tarde.",
    err_buscar_conversas: "Ocorreu um erro ao buscar as conversas. Tente novamente mais tarde.",
    err_salvar_arquivo: "Ocorreu um erro ao salvar o arquivo. Tente novamente mais tarde.",
    err_arquivar_conversa: "Ocorreu um erro ao alterar o status da conversa. Tente novamente mais tarde.",
    err_marcar_nao_lida: "Ocorreu um erro ao marcar a conversa como não lida. Tente novamente mais tarde.",
    err_marcar_mensagens_visualizada: "Ocorreu um erro ao marcar as mensagens como visualizadas.",
    err_interno: "Ocorreu um erro interno. Tente novamente mais tarde.",
    err_retornar_lista_conversas_setores:
        "Ocorreu um erro ao retornar as listas de conversas dos setores. Tente novamente mais tarde.",
    err_retornar_lista_conversas_usuario:
        "Ocorreu um erro ao retornar as listas de conversas pelas quais você é responsável. Tente novamente mais tarde.",
    err_alterar_usuario_responsavel:
        "Ocorreu um erro ao alterar o responsável da conversa. Tente novamente mais tarde.",
    err_alterar_setor_conversa: "Ocorreu um erro ao alterar o setor da conversa. Tente novamente mais tarde.",
    err_todos_campos_obrigatorios: "Todos os campos devem ser preenchidos.",
    err_criar_conversa: "Ocorreu um erro ao criar a conversa. Tente novamente mais tarde.",
    err_buscar_usuario: "Ocorreu um erro ao buscar informações do usuario. Tente novamente mais tarde.",
    err_url_websocket_invalida: "URL do Websocket inválida",
    err_conectar_ws: "Ocorreu um erro ao se conectar ao Websocket.",
    err_alterar_nome_contato: "Ocorreu um erro ao alterar o nome do contato. Tente novamente mais tarde.",
    err_enviar_audio: "Ocorreu um erro ao enviar o audio. Tente novamente mais tarde.",

    //LBL - LABELS
    lbl_enviou_imagem: "Enviou uma imagem",
    lbl_enviou_video: "Enviou um video",
    lbl_enviou_arquivo: "Enviou um arquivo",
    lbl_enviou_audio: "Enviou um audio",
    lbl_enviou_contato: "Enviou um contato",
    lbl_enviou_localizacao: "Enviou uma localização",
    lbl_respondendo_imagem: "Imagem Respondida",
    lbl_respondendo_video: "Video Respondido",
    lbl_respondendo_arquivo: "Arquivo Respondido",
    lbl_respondendo_audio: "Audio Respondido",
    lbl_respondendo_contato: "Contato Enviado",
    lbl_respondendo_localizacao: "Respondendo uma mensagem de localização",
    lbl_enviou_mensagem: "Enviou uma mensagem",
    lbl_nenhuma_mensagem_conversa: "Nenhuma mensagem nessa conversa!",
    lbl_mensagem_imagem: "Mensagem com imagem",
    lbl_mensagem_documento: "Mensagem com documento",
    lbl_arquivo_selecionado: "Arquivos selecionados",
    lbl_endereco: "Endereço",
    lbl_localizacao: "Localização",
    lbl_conversas_abertas: "Conversas Abertas",
    lbl_conversas_solucionadas: "Conversas Solucionadas",
    lbl_contato: "Contato",
    lbl_conversas: "Conversas",
    lbl_nome_contato: "Nome do contato",
    lbl_tel_contato: "Telefone",
    lbl_nome_atual: "Nome Atual",
    lbl_novo_nome: "Novo nome",

    //OPC - OPÇÕES
    opc_responder: "Responder",
    opc_assumir: "Assumir",
    opc_transferir: "Transferir",
    opc_nao_lida: "Não lida",
    opc_solucionado: "Solucionado",
    opc_reabrir: "Reabrir",
    opc_desvincular: "Desvincular",

    //PLC - PLACEHOLDERS
    plc_pesquisa_chat_whatsapp: "Buscar contato pelo nome ou número",
    plc_escreva_mensagem: "Escreva uma mensagem",
    plc_pesquisar: "Pesquisar",

    // MENU - MENU NAMES
    menu_embarque: "Embarques",
    menu_carga: "Cargas",
    menu_cadastro: "Cadastros",
    menu_fiscal: "Fiscal",
    menu_financeiro: "Financeiro",
    menu_gestao: "Gestão",
    menu_qualidade: "Qualidade",
    menu_frota: "Frota",

    //SUB_MENU - SUB MENU NAMES
    sub_menu_embarque_mapa_ope: "Mapa Operacional",
    sub_menu_embarque_listagem: "Listagem",
    sub_menu_embarque_conexoes: "Conexões",
    sub_menu_carga_gestao: "Gestão",
    sub_menu_carga_listagem: "Listagem",
    sub_menu_carga_cotacao: "Cotação",
    sub_menu_cadastro_parceiro: "Parceiro",
    sub_menu_cadastro_motorista: "Motorista",
    sub_menu_cadastro_veiculo: "Veículo",
    sub_menu_cadastro_filial: "Filial",
    sub_menu_cadastro_proprietario: "Proprietário",
    sub_menu_cadastro_referencia: "Referências",
    sub_menu_fiscal_ct_e: "CT-e",
    sub_menu_fiscal_mdf_e: "MDF-e",
    sub_menu_fiscal_nfe_recebidas: "NF-e Recebidas",
    sub_menu_fiscal_nf_entrada: "NF de Entrada",
    sub_menu_fiscal_relatorio_nf: "Relatório NF",
    sub_menu_fiscal_contabilidade: "Contabilidade",
    sub_menu_financeiro_recebiveis: "Recebiveis",
    sub_menu_financeiro_faturas: "Faturas",
    sub_menu_financeiro_analise_credito: "Analise de Credito",
    sub_menu_financeiro_gastos: "Gastos",
    sub_menu_financeiro_rel_gastos: "Relatório de Gastos",
    sub_menu_financeiro_conta_bancaria: "Contas Bancárias",
    sub_menu_financeiro_relatorio: "Relatórios",
    sub_menu_financeiro_demonstrativo: "Demonstrativo",
    sub_menu_financeiro_fluxo_caixa: "Fluxo de Caixa",
    sub_menu_financeiro_conciliacao_bancaria: "Conciliação Bancária",
    sub_menu_financeiro_conciliacao_ofx: "Conciliação OFX",
    sub_menu_gestao_dashboard_embarques: "Dashboard Embarques",
    sub_menu_gestao_dashboard_csc: "Dashboard CSC",
    sub_menu_gestao_dashboard_logistica: "Dashboard Logística",
    sub_menu_gestao_relatorio_metricas: "Relatório Métricas",
    sub_menu_gestao_indice_atendimento: "Índice de Atendimento",
    sub_menu_gestao_planilhas: "Planilhas",
    sub_menu_gestao_relatorio_csc: "Relatórios CSC",
    sub_menu_gestao_relatorio_logistica: "Relatórios Logística",
    sub_menu_gestao_relatorio_produto: "Relatórios de Produto",
    sub_menu_qualidade_patrimonio: "Patrimônio",
    sub_menu_qualidade_pontos_melhoria: "Pontos de Melhoria",
    sub_menu_frota_proximas_viagens: "Próximas Viagens",
    sub_menu_frota_motoristas: "Motoristas",
    sub_menu_frota_veiculos: "Veículos",
    sub_menu_frota_conjuntos: "Conjuntos",
    sub_menu_frota_cadastro_itens: "Cadastro de Itens",
    sub_menu_frota_almoxarifados: "Almoxarifados",
    sub_menu_frota_itens: "Itens",
    sub_menu_frota_infracoes: "Infracões",
    sub_menu_frota_relatorio: "Relatórios",

    // TIT - Titles
    tit_desassociar_minhas_conversas: "Desassociar minhas conversas",
    tit_criar_conversa: "Criar nova conversa",
    tit_exibir_conversas: "Exibir apenas conversas não associadas",
    tit_alterar_nome_contato: "Alterar nome do contato",

    //TLT - Tooltips
    tlt_template_conversa_inativa: "Enviar mensagem para iniciar conversa inativa",
};
