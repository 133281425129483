import { retornarListaConversasPesquisa } from "@/api/chatAPI";
import { ConversaType } from "@/types/ConversaTypes";
import { MenuLateralEnum, MenuLateralType } from "@/types/MenuTypes";
import { ordenarConversasPorData } from "@/util/ChatUtils";
import { create } from "zustand";

interface ListagemLateralState {
    menuLateral: MenuLateralType;
    conversas: ConversaType[];
    conversasFiltradas: ConversaType[];
    apenasNaoAssociadas: boolean;
    paginaListagem: number;
    tamanhoListagem: number;
}

interface ListagemLateralActions {
    alterarMenuLateral: (menu: MenuLateralType) => void;
    adicionarListaConversas: (listaConversas: ConversaType[]) => void;
    removerConversa: (conversaRemovida: ConversaType) => void;
    adicionaOuAlterarConversa: (conversaAlterada: ConversaType) => void;
    pesquisarConversas: (texto: string) => void;
    alterarStatusApenasNaoAssociadas: (statusApenasNaoAssociadas: boolean) => void;
    alterarPaginaListagem: (pagina: number) => void;
}

export const useListagemLateralStore = create<ListagemLateralState & ListagemLateralActions>((set, get) => ({
    conversas: [],
    conversasFiltradas: [],
    menuLateral: MenuLateralEnum.CONVERSAS_ABERTAS,
    apenasNaoAssociadas: true,
    paginaListagem: 0,
    tamanhoListagem: 100,
    alterarMenuLateral: (menu: MenuLateralType) => {
        set((state) => ({
            menuLateral: menu,
        }));
    },

    adicionarListaConversas: (listaConversas: ConversaType[]) => {
        const apenasNaoAssociadas = get().apenasNaoAssociadas;
        let conversasFiltro;

        if (apenasNaoAssociadas) {
            conversasFiltro = listaConversas?.filter((conversa) => {
                if (!conversa.setor && !conversa.usuarioResponsavel) return conversa;
            });
        } else {
            conversasFiltro = listaConversas;
        }

        set((state) => ({
            conversas: ordenarConversasPorData(listaConversas),
            conversasFiltradas: conversasFiltro,
        }));
    },
    adicionaOuAlterarConversa: (conversaAlterada: ConversaType) => {
        const conversas = get().conversas;
        if (!conversas) {
            console.log("Conversa não encontrada");
            return;
        }
        const index = conversas?.findIndex((item) => item.codConversa === conversaAlterada.codConversa);
        let conversasOrdenadas;
        if (index > -1) {
            conversasOrdenadas = ordenarConversasPorData([
                ...conversas.slice(0, index),
                conversaAlterada,
                ...conversas.slice(index + 1),
            ]);
        } else {
            conversasOrdenadas = ordenarConversasPorData([...conversas, conversaAlterada]);
        }

        set((state) => ({
            conversas: conversasOrdenadas,
        }));
    },

    removerConversa: (conversaRemovida: ConversaType) => {
        set((state) => ({
            conversas: state.conversas?.filter((conversa) => conversa.codConversa !== conversaRemovida.codConversa),
            conversasFiltradas: state.conversasFiltradas?.filter(
                (conversa) => conversa.codConversa !== conversaRemovida.codConversa
            ),
        }));
    },
    pesquisarConversas: (texto: string) => {
        const conversas = get().conversas;
        const apenasNaoAssociadas = get().apenasNaoAssociadas;
        if (texto.length >= 3) {
            retornarListaConversasPesquisa(texto)
                .then((conversasPesquisadas) => {
                    set((state) => ({
                        conversasFiltradas: ordenarConversasPorData(conversasPesquisadas.content),
                    }));
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (apenasNaoAssociadas) {
            set((state) => ({
                conversasFiltradas: conversas?.filter((conversa) => {
                    if (!conversa.setor && !conversa.usuarioResponsavel) return conversa;
                }),
            }));
        } else {
            set((state) => ({
                conversasFiltradas: conversas,
            }));
        }
    },
    alterarStatusApenasNaoAssociadas: (statusApenasNaoAssociadas: boolean) => {
        const conversas = get().conversas;

        if (statusApenasNaoAssociadas) {
            set((state) => ({
                apenasNaoAssociadas: statusApenasNaoAssociadas,
                conversasFiltradas: conversas?.filter((conversa) => {
                    if (!conversa.setor && !conversa.usuarioResponsavel) return conversa;
                }),
            }));
        } else {
            set((state) => ({
                apenasNaoAssociadas: statusApenasNaoAssociadas,
                conversasFiltradas: conversas,
            }));
        }
    },

    alterarPaginaListagem: (pagina: number) => {
        set((state) => ({
            paginaListagem: pagina,
        }));
    },
}));
