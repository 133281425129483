import { create } from "zustand";

type ModalStore = {
    aberto: boolean;
    conteudo: React.ReactNode | null;
    abrirModal: (conteudo: React.ReactNode) => void;
    fecharModal: () => void;
};

export const useModalStore = create<ModalStore>()((set) => ({
    aberto: false,
    conteudo: null,
    abrirModal: (conteudo) => set({ aberto: true, conteudo }),
    fecharModal: () => set({ aberto: false, conteudo: null }),
}));
