"use client";
import { useModalStore } from "@/store/modalStore";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = () => {
    const { aberto, conteudo, fecharModal } = useModalStore();

    if (!aberto) return null;

    return (
        <div
            className="fixed top-0 z-30 left-0 w-full h-full bg-black/50 flex items-center justify-center"
            onClick={fecharModal}>
            <div
                className="w-1/3 bg-white p-6 rounded-lg shadow-md relative"
                onClick={(e) => e.stopPropagation()}>
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={fecharModal}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        color="#000"
                        size="lg"
                    />
                </button>
                {conteudo}
            </div>
        </div>
    );
};

export default Modal;
