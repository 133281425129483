"use client";
import { retornaListaConversas, retornaListaConversasSetores, retornaListaConversasUsuario } from "@/api/chatAPI";
import { EsqueletoConversas } from "@/components/carregamento/EsqueletoConversas";
import { InputBuscaPadrao } from "@/components/inputs/Input";
import CriarConversa from "@/components/modal/conteudo/CriarConversa";
import { ptBR } from "@/i18n/pt-br";
import { useConversaStore } from "@/store/conversaStore";
import { useListagemLateralStore } from "@/store/listagemLateralStore";
import { useModalStore } from "@/store/modalStore";
import { useUsuarioStore } from "@/store/usuarioStore";
import { StatusAtendimentoType } from "@/types/GeralTypes";
import { MenuLateralEnum } from "@/types/MenuTypes";
import { unirArrayConversaSemDuplicadas } from "@/util/ChatUtils";
import { faCommentMedical, faUsers, faUsersSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import CaixaConversa from "./CaixaConversa";

const i18n = ptBR;

const ListagemConversa = () => {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [textoBusca, setTextoBusca] = useState<string>("");
    const { conversa } = useConversaStore();
    const { usuario } = useUsuarioStore();
    const { abrirModal } = useModalStore();
    const {
        apenasNaoAssociadas,
        conversas,
        conversasFiltradas,
        adicionarListaConversas,
        pesquisarConversas,
        menuLateral,
        alterarStatusApenasNaoAssociadas,
        paginaListagem,
        tamanhoListagem,
        alterarPaginaListagem,
    } = useListagemLateralStore();
    const observerTarget = useRef(null);

    const carregarMaisConversas = useCallback(async () => {
        if (menuLateral !== MenuLateralEnum.SOLUCIONADO) return;
        let conversasPaginadas = await retornaListaConversas(
            StatusAtendimentoType.SOLUCIONADO,
            paginaListagem.toString(),
            tamanhoListagem.toString()
        );
        let retornoConversas = conversasPaginadas.content;
        const arraySemDuplicados = unirArrayConversaSemDuplicadas(conversas, retornoConversas);
        alterarPaginaListagem(paginaListagem + 1);
        adicionarListaConversas(arraySemDuplicados);
    }, [adicionarListaConversas, alterarPaginaListagem, conversas, menuLateral, paginaListagem, tamanhoListagem]);

    const carregaListagemInicial = async () => {
        setCarregando(true);
        try {
            let retornoConversas;
            switch (menuLateral) {
                case MenuLateralEnum.CONVERSAS_ABERTAS: {
                    let conversasPaginadas = await retornaListaConversas(
                        StatusAtendimentoType.ABERTO,
                        "0",
                        tamanhoListagem.toString()
                    );
                    retornoConversas = conversasPaginadas.content;
                    break;
                }
                case MenuLateralEnum.MEUS_SETORES:
                    if (usuario) {
                        const arrSetores = usuario.setores.replace(/\s+/g, "").split(",");
                        retornoConversas = await retornaListaConversasSetores(arrSetores);
                    }
                    break;
                case MenuLateralEnum.MINHAS_CONVERSAS:
                    retornoConversas = await retornaListaConversasUsuario(usuario?.login);
                    break;
                case MenuLateralEnum.SOLUCIONADO: {
                    adicionarListaConversas([]);
                    let conversasPaginadas = await retornaListaConversas(
                        StatusAtendimentoType.SOLUCIONADO,
                        paginaListagem.toString(),
                        tamanhoListagem.toString()
                    );
                    retornoConversas = conversasPaginadas.content;
                    alterarPaginaListagem(paginaListagem + 1);
                    break;
                }
                default:
                    break;
            }
            adicionarListaConversas(retornoConversas);
        } catch (erro: any) {
            toast(erro.message, {
                toastId: "erro-listagem-conversas",
                type: "error",
            });
        } finally {
            setCarregando(false);
        }
    };

    const handleFiltroConversasNaoAssociadas = () => {
        if (textoBusca) return;
        alterarStatusApenasNaoAssociadas(!apenasNaoAssociadas);
    };

    useEffect(() => {
        pesquisarConversas(textoBusca);
    }, [textoBusca, pesquisarConversas, conversas]);

    useEffect(() => {
        carregaListagemInicial();
        alterarPaginaListagem(0);
        menuLateral === MenuLateralEnum.CONVERSAS_ABERTAS
            ? alterarStatusApenasNaoAssociadas(true)
            : alterarStatusApenasNaoAssociadas(false);
    }, [menuLateral]);

    useEffect(() => {
        if (textoBusca) {
            return;
        }
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    carregarMaisConversas();
                }
            },
            { threshold: 1 }
        );

        const target = observerTarget.current;

        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [observerTarget, carregarMaisConversas, textoBusca]);

    return (
        <aside className="bg-gray-100 flex flex-col h-full">
            <div className="space-y-auto border-b border-slate-200">
                <span className="flex w-11/12 mx-auto justify-between items-center my-2">
                    <h1 className="text-xl md:text-2xl">{menuLateral}</h1>
                    <span className="flex space-x-2">
                        <button
                            className="hover:cursor-pointer"
                            title={i18n.tit_criar_conversa}
                            onClick={() => abrirModal(<CriarConversa />)}>
                            <FontAwesomeIcon
                                icon={faCommentMedical}
                                size="xl"
                                color="#15803D"
                            />
                        </button>
                        {menuLateral === MenuLateralEnum.CONVERSAS_ABERTAS && (
                            <button
                                className="hover:cursor-pointer"
                                title={i18n.tit_exibir_conversas}
                                onClick={handleFiltroConversasNaoAssociadas}>
                                <FontAwesomeIcon
                                    icon={apenasNaoAssociadas ? faUsers : faUsersSlash}
                                    size="xl"
                                    color={"#15803D"}
                                />
                            </button>
                        )}
                    </span>
                </span>
                <div className="flex w-11/12 mx-auto rounded-xl mb-4 items-center">
                    <InputBuscaPadrao
                        placeholder={i18n.plc_pesquisa_chat_whatsapp}
                        className="p-2 !rounded-full border border-slate-400 focus:outline-none"
                        onChange={(e) => setTextoBusca(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex-1 overflow-y-auto scroll-smooth">
                {carregando ? (
                    <EsqueletoConversas />
                ) : (
                    conversasFiltradas?.map((conversaLista) => (
                        <CaixaConversa
                            key={conversaLista.codConversa}
                            conversa={conversaLista}
                            selecionado={conversaLista.codConversa === conversa?.codConversa}
                        />
                    ))
                )}
                <div ref={observerTarget} />
            </div>
        </aside>
    );
};

export default ListagemConversa;
