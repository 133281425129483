import { create } from "zustand";

type audioStore = {
    audioEnvio: Blob | null;
    codMensagemReproduzindo?: string;
    alterarAudioReproduzindo: (codMensagem: string | undefined) => void;
    adicionarAudioEnvio: (novoAudioEnvio: Blob) => void;
    removerAudioEnvio: () => void;
};

export const useAudioStore = create<audioStore>()((set) => ({
    audioEnvio: null,
    codMensagemReproduzindo: undefined,
    alterarAudioReproduzindo: (codMensagem: string | undefined) => {
        set((state) => ({
            codMensagemReproduzindo: codMensagem,
        }));
    },
    adicionarAudioEnvio: (novoAudioEnvio: Blob) => {
        set((state) => ({
            audioEnvio: novoAudioEnvio,
        }));
    },
    removerAudioEnvio: () => {
        set((state) => ({
            audioEnvio: null,
        }));
    },
}));
