import { visualizarMensagensConversa } from "@/api/chatAPI";
import { ptBR } from "@/i18n/pt-br";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { CookieEnum, ordenarMensagensPorData } from "@/util/ChatUtils";
import { CompatClient, Stomp } from "@stomp/stompjs";
import { getCookie } from "cookies-next";
import { differenceInHours } from "date-fns";
import { toast } from "react-toastify";
import SockJS from "sockjs-client";
import { create } from "zustand";

const getChatWebSocketURL = () => process.env.NEXT_PUBLIC_CHAT_API_WEBSOCKET_URL;
const i18n = ptBR;

interface ConversaState {
    conversa: ConversaType | null;
    stompClient: CompatClient | null;
    carregando: boolean;
    mensagens: MensagemType[];
    mensagemEnviadaPeloUsuario: boolean;
    paginaMensagens: number;
    tamanhoPagina: number;
    totalPaginas: number;
}

interface ConversaActions {
    alteraCarregando: (carregando: boolean) => void;
    adicionarMensagem: (novaMensagem: MensagemType) => void;
    alterarConversaAtiva: (novaConversa: ConversaType) => void;
    adicionarListaMensagens: (listaMensagens: MensagemType[]) => void;
    desconectarWS: () => void;
    conectarWS: () => void;
    desconectarConversaAntiga: (codConversaAntiga: string | null | undefined) => void;
    verificarSeConversaInativa: () => boolean;
    alterarPaginaMensagens: (novaPagina: number) => void;
    alterarTotalPaginas: (novoTotalPaginas: number) => void;
}

export const useConversaStore = create<ConversaState & ConversaActions>((set, get) => ({
    conversa: null,
    mensagens: [],
    stompClient: null,
    carregando: false,
    mensagemEnviadaPeloUsuario: false,
    paginaMensagens: 0,
    tamanhoPagina: 15,
    totalPaginas: 1,
    alteraCarregando: (carregando: boolean) => set({ carregando }),

    adicionarListaMensagens: (listaMensagens: MensagemType[]) => {
        set((state) => ({
            mensagens: ordenarMensagensPorData([...state.mensagens, ...listaMensagens]),
        }));
    },

    adicionarMensagem: (novaMensagem: MensagemType) => {
        const loginUsuario =
            process.env.NEXT_PUBLIC_AMBIENTE === "local" ? "sistema" : getCookie(CookieEnum.LOGIN_USUARIO);

        const mensagensAtuais = get().mensagens;
        const index = mensagensAtuais.findIndex((mensagem) => mensagem.codMensagem === novaMensagem.codMensagem);

        const listaMensagens =
            index > -1
                ? [...mensagensAtuais.slice(0, index), novaMensagem, ...mensagensAtuais.slice(index + 1)]
                : [...mensagensAtuais, novaMensagem];

        const isMensagemEnviadaPeloUsuario = loginUsuario
            ? novaMensagem.remetente.toLocaleLowerCase() === loginUsuario.toLocaleLowerCase()
            : false;

        set({ mensagens: listaMensagens, mensagemEnviadaPeloUsuario: isMensagemEnviadaPeloUsuario });
    },

    alterarConversaAtiva: (novaConversa: ConversaType) => set({ conversa: novaConversa }),

    conectarWS: () => {
        const conversa = get().conversa;
        const url = getChatWebSocketURL();
        if (!url) {
            toast(i18n.err_url_websocket_invalida, { toastId: "erro-conectar-ws", type: "error" });
            return;
        }
        const socketFactory = () => new SockJS(url);
        const stompClient = Stomp.over(socketFactory);

        stompClient.connect(
            {},
            () => {
                if (conversa) {
                    stompClient.subscribe(`/conversa/${conversa.codConversa}/nova-mensagem`, (mensagemWS: any) => {
                        const novaMensagem: MensagemType = JSON.parse(mensagemWS.body);
                        if (novaMensagem.codConversa !== conversa?.codConversa) return;
                        get().adicionarMensagem(novaMensagem);
                    });
                    visualizarMensagensConversa(conversa.codConversa);
                }
            },
            (error: any) => {
                toast(i18n.err_conectar_ws, { toastId: "erro-conectar-ws", type: "error" });
                console.error(i18n.err_conectar_ws, error);
            }
        );

        set({ stompClient });
    },

    desconectarWS: () => {
        get().stompClient?.disconnect();
        set({ stompClient: null });
    },

    desconectarConversaAntiga: (codConversaAntiga: string | null | undefined) => {
        const stompClient = get().stompClient;
        if (codConversaAntiga && stompClient?.connected) {
            stompClient?.unsubscribe(`/conversa/${codConversaAntiga}/nova-mensagem`);
        }
        set({ mensagens: [] });
    },

    verificarSeConversaInativa: () => {
        const conversa = get().conversa;
        const dataHoraAtual = new Date();

        if (!conversa?.ultimaMensagem?.dataHoraEnvio) return true;

        const dataHoraUltimaMensagem = new Date(conversa.ultimaMensagem?.dataHoraEnvio);
        const diferencaEmHoras = Math.abs(differenceInHours(dataHoraAtual, dataHoraUltimaMensagem));
        return diferencaEmHoras > 24;
    },

    alterarPaginaMensagens: (novaPagina: number) => set({ paginaMensagens: novaPagina }),
    alterarTotalPaginas: (novaTotalPaginas: number) => set({ totalPaginas: novaTotalPaginas }),
}));
