export const formatarTelefone = (telefone: string | undefined): string => {
    if (!telefone) {
        return "";
    }
    let telefoneApenasNumerico = telefone.replace(/\D/g, "");
    const telefoneNaoPossuiCodigoArea = telefoneApenasNumerico.length === 10 || telefoneApenasNumerico.length === 11;

    telefoneApenasNumerico = telefoneNaoPossuiCodigoArea ? `55${telefoneApenasNumerico}` : telefoneApenasNumerico;

    if (telefoneApenasNumerico.length === 13) {
        const ddd = telefoneApenasNumerico.slice(2, 4);
        const numero = telefoneApenasNumerico.slice(4);
        return `(${ddd}) ${numero.slice(0, 5)}-${numero.slice(5)}`;
    } else if (telefoneApenasNumerico.length === 12) {
        const ddd = telefoneApenasNumerico.slice(2, 4);
        const numero = telefoneApenasNumerico.slice(4);
        return `(${ddd}) ${numero.slice(0, 4)}-${numero.slice(4)}`;
    } else {
        return telefone;
    }
};
