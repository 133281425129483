export enum SetorEnum {
    Cadastro = "Cadastro",
    Agendamento = "Agendamento",
    Fiscal = "Fiscal",
    Descarga = "Descarga",
    Financeiro = "Financeiro",
    Logistica = "Logística",
}

export enum TemplateEnum {
    INICIAR_CONVERSA_MOT_FRETE_ATIVO = "INICIAR_CONVERSA_MOT_FRETE_ATIVO",
    INICIAR_NOVA_CONVERSA = "INICIAR_NOVA_CONVERSA",
    SOLICITAR_LOCALIZACAO = "SOLICITAR_LOCALIZACAO",
    SOLICITAR_DOCUMENTOS_CADASTRO = "SOLICITAR_DOCUMENTOS_CADASTRO",
    ANALISE_GERENCIAMENTO_RISCO = "ANALISE_GERENCIAMENTO_RISCO",
}
