import { ptBR } from "@/i18n/pt-br";
import { MensagemType } from "@/types/MensagemTypes";
const i18n = ptBR;
interface OpcoesMensagemProps {
    isRemetente: boolean;
    mostrarOpcoes: boolean;
    mensagem: MensagemType;
    setMensagemResposta: React.Dispatch<React.SetStateAction<MensagemType | undefined>>;
    clickOpcao: () => void;
}
const OpcoesMensagem = ({
    isRemetente,
    mensagem,
    setMensagemResposta,
    clickOpcao,
    mostrarOpcoes,
}: OpcoesMensagemProps) => {
    const responderMensagem = () => {
        setMensagemResposta(mensagem);
        clickOpcao();
    };

    return (
        <>
            <nav
                className={`${mostrarOpcoes ? "visible" : "invisible"} z-30 h-10
                        ${isRemetente ? "right-1" : "left-1 "} my-auto rounded-md mx-1
                        text-slate-100 shadow-lg bg-green-600 border-green-900 text-sm`}>
                <ul className="flex flex-col items-start justify-start">
                    <button
                        className={`rounded-md w-full text-left p-2 hover:cursor-pointer hover:bg-green-300`}
                        onClick={() => {
                            responderMensagem();
                            clickOpcao();
                        }}>
                        {i18n.opc_responder}
                    </button>
                </ul>
            </nav>
            {mostrarOpcoes ? (
                <button
                    className="fixed top-0 right-0 bottom-0 left-0 z-20"
                    onClick={clickOpcao}></button>
            ) : (
                <></>
            )}
        </>
    );
};
export default OpcoesMensagem;
