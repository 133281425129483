import { ptBR } from "@/i18n/pt-br";
import { MensagemType } from "@/types/MensagemTypes";
import { TipoMensagemEnum } from "@/util/ChatUtils";
import { formatarTelefone } from "@/util/FormatacaoUtils";

const i18n = ptBR;
const CabecalhoMensagemResposta = ({ mensagemResposta }: { mensagemResposta: MensagemType }) => {
    const retornaTextoRespostaMensagem = (mensagem: MensagemType | undefined) => {
        if (!mensagem) return "";

        switch (mensagem.tipo) {
            case TipoMensagemEnum.IMAGE:
                return `${i18n.lbl_respondendo_imagem}: ${mensagem.nomeArquivo}`;
            case TipoMensagemEnum.VIDEO:
                return `${i18n.lbl_respondendo_video}: ${mensagem.nomeArquivo}`;
            case TipoMensagemEnum.AUDIO:
                return `${i18n.lbl_respondendo_audio}: ${mensagem.nomeArquivo}`;
            case TipoMensagemEnum.DOCUMENT:
                return `${i18n.lbl_respondendo_arquivo}: ${mensagem.nomeArquivo}`;
            case TipoMensagemEnum.CONTACTS:
                return `${i18n.lbl_respondendo_contato}: ${mensagem.contato?.nomeContato} - ${formatarTelefone(mensagem.contato?.telContato)}`;
            case TipoMensagemEnum.LOCATION:
                return `${i18n.lbl_respondendo_localizacao}`;
            default:
                return null;
        }
    };
    return (
        <a
            href={`#${mensagemResposta.codMensagem}`}
            className="px-4 max-w-72 text-left">
            <span className="text-green-700 font-semibold">
                {`${mensagemResposta.remetente} - `}
                <span className="text-sm font-medium text-gray-500">{mensagemResposta.dataHoraEnvio}</span>
            </span>
            <p className="text-sm truncate">{mensagemResposta.texto}</p>
            <span className="text-sm font-medium text-gray-500">{retornaTextoRespostaMensagem(mensagemResposta)}</span>
        </a>
    );
};

export default CabecalhoMensagemResposta;
